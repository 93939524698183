// import { EChartOption } from 'echarts';
// import * as echarts from 'echarts';
import { CustomSeriesOption } from 'echarts';
import { GeologyInterval } from 'lib/mudLogDataFrame';

// import { merge } from 'lodash';
import { lithImages } from '../../../lib/LithologyImages';
import { MathUtils } from '../../../lib/mathUtil';
type CustomSeriesRenderItem = CustomSeriesOption['renderItem'];

export const renderGeologyIntervals: CustomSeriesRenderItem = (params, api) => {
  // console.log('rendering');
  // let codeLith = api
  // var currentIndex = api.value!(0);
  var geoIntv: GeologyInterval = api.value!(1) as any;
  let p1 = api.coord([geoIntv.shape.lineTop.point1.X, geoIntv.shape.lineTop.point1.Y]);
  let p2 = api.coord([geoIntv.shape.lineTop.point2.X, geoIntv.shape.lineTop.point2.Y]);
  let p3 = api.coord([geoIntv.shape.lineBottom.point1.X, geoIntv.shape.lineBottom.point1.Y]);
  let p4 = api.coord([geoIntv.shape.lineBottom.point2.X, geoIntv.shape.lineBottom.point2.Y]);

  let lastPcStart = 0;
  let lastPcEnd = 0;
  let elements: any[] = [];
  let lineTop: MathUtils.Line = { point1: { X: p1[0], Y: p1[1] }, point2: { X: p2[0], Y: p2[1] } };
  let lineBottom: MathUtils.Line = { point1: { X: p3[0], Y: p3[1] }, point2: { X: p4[0], Y: p4[1] } };
  geoIntv.lith.forEach((l) => {
    lastPcEnd += l.lithPc;
    let lineTopLith = MathUtils.scaleLine(lineTop, lastPcEnd / 100, lastPcStart / 100);
    let lineBottomLith = MathUtils.scaleLine(lineBottom, lastPcEnd / 100, lastPcStart / 100);
    const pointToArr = (p: MathUtils.Point) => [p.X, p.Y];
    let el = {
      type: 'polygon',
      // transition: ['shape'],
      shape: {
        points: [
          pointToArr(lineTopLith.point1),
          pointToArr(lineTopLith.point2),
          pointToArr(lineBottomLith.point2),
          pointToArr(lineBottomLith.point1),
        ],
      },
      style: api.style({
        borderWidth: 0,
        fill: {
          image: (lithImages as any)[l.codeLith],
        } as any, //fix typescript error, this is actually used in echarts
      }),
    };
    elements.push(el);
    lastPcStart += l.lithPc;
  });
  // console.log(params);
  // console.log(api);
  // console.log(currentIndex);
  // console.log(geoIntv);

  // let el1 = {
  //   type: 'polygon',
  //   // transition: ['shape'],
  //   shape: {
  //     points: [p1, p2, p4, p3],
  //   },
  //   style: api.style({
  //     fill: {
  //       image: lithImages[geoIntv.lith[0].codeLith],
  //     },
  //   }),
  // };

  return {
    type: 'group',
    children: elements,
  };
};
