// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace MathUtils {
  export interface Point {
    X: number;
    Y: number;
  }
  export interface Line {
    point1: Point;
    point2: Point;
  }

  export class LinearFunction {
    //linear function: y = a * x + b;
    private a = 0;
    private b = 0;
    private c = 0;
    private angle = 0;
    readonly precision = 8;

    evalX: (y: number) => number;
    evalY: (x: number) => number;
    horizontalOrVertical: 'Horizontal' | 'Vertical' | 'Oblique' = 'Oblique';

    constructor(point: Point, angle: number) {
      this.angle = LinearFunction.Mod_Angle(angle);
      // this.point = point;
      // this.angle = angle;
      if (this.angle % 180 === 0) {
        this.horizontalOrVertical = 'Horizontal';
        this.b = point.Y;
        // this.a = Number.NaN
        this.a = 0;
        this.evalX = (y) => Number.NaN;
        this.evalY = (x) => this.b;
      }
      //  else if (this.angle % 90 < 0.00001) {
      //   this.horizontalOrVertical = 'Vertical';
      //   this.b = point.X;
      //   this.a = Number.NaN;
      //   this.c = 0;
      //   this.evalX = (y) => this.b;
      //   this.evalY = (x) => Number.NaN;
      // }
      else if (this.angle % 90 < 0.001) {
        this.horizontalOrVertical = 'Vertical';
        this.c = point.X;
        this.a = Number.NaN;
        this.b = Number.NaN;
        this.evalX = (y) => this.c;
        this.evalY = (x) => Number.NaN;
      } else {
        this.a = Math.tan((this.angle * Math.PI) / 180);
        this.b = point.Y - this.a * point.X;
        this.evalY = (x) => this.round(this.a * x + this.b);
        this.evalX = (y) => this.round((y - this.b) / this.a);
      }

      this.a = this.round(this.a);
      this.b = this.round(this.b);
      this.c = this.round(this.c);
    }
    static GetLinearFuncByLine(line: Line) {
      let vec = vector(line.point1, line.point2);
      let angle = calcVectorAngle(vec);
      return new LinearFunction(line.point1, angle);
    }
    static Mod_Angle(angle: number) {
      let m = angle % 360;
      return m < 0 ? m + 360 : m;
    }
    private round(n: number) {
      return +n.toFixed(this.precision);
    }

    intersect(theOtherLine: LinearFunction): Point | undefined {
      if ((Number.isNaN(this.a) && Number.isNaN(theOtherLine.a)) || this.a === theOtherLine.a) {
        //两条线平行，方程无解
        return undefined;
      } else {
        // (x3 - x1) / (y3 - y1 ) = tan(theta1)
        // (x3 - x2) / (y3 - y2 ) = tan(theta2)
        let x = Number.NaN;
        let y = Number.NaN;
        if (this.horizontalOrVertical === 'Vertical') {
          //line 2 wont be vertical
          x = this.evalX(0);
          y = theOtherLine.evalY(x);
        } else if (theOtherLine.horizontalOrVertical === 'Vertical') {
          x = theOtherLine.evalX(0);
          y = this.evalY(x);
        } else if (this.horizontalOrVertical === 'Horizontal') {
          y = this.evalY(0);
          x = theOtherLine.evalX(y);
        } else if (theOtherLine.horizontalOrVertical === 'Horizontal') {
          y = theOtherLine.evalY(0);
          x = this.evalX(y);
        } else {
          x = -(this.b - theOtherLine.b) / (this.a - theOtherLine.a);
          y = this.evalY(x);
        }

        x = +x.toFixed(this.precision);
        y = +y.toFixed(this.precision);

        return { X: x, Y: y };
      }
    }

    printFunc() {
      console.log(`a=${this.a}, b=${this.b},c=${this.c},angle=${this.angle} hz=${this.horizontalOrVertical}`);
    }
  }

  export interface Vector extends Point {}

  export function vector(point1: Point, point2: Point): Vector {
    return { X: point2.X - point1.X, Y: point2.Y - point1.Y };
  }
  export function rotate(cx: number, cy: number, x: number, y: number, angle: number) {
    var radians = (Math.PI / 180) * angle,
      cos = Math.cos(radians),
      sin = Math.sin(radians),
      nx = cos * (x - cx) + sin * (y - cy) + cx,
      ny = cos * (y - cy) - sin * (x - cx) + cy;
    return [nx, ny];
  }

  export function rotate3(centerPoint: Point, point: Point, angle: number): Point {
    let result = rotate(centerPoint.X, centerPoint.Y, point.X, point.Y, angle);
    return { X: result[0], Y: result[1] };
  }
  export function rotate2(centerPoint: Point, point: Point, angle: number): Point {
    let radians = (Math.PI / 180) * angle;
    let s = Math.sin(radians);
    let c = Math.cos(radians);

    let p: Point = clone(point);
    // translate point back to origin:
    p.X -= centerPoint.X;
    p.Y -= centerPoint.Y;

    let xnew = 0;
    let ynew = 0;
    // rotate point
    // if (angle === 90) {
    //   console.log('rotate 90deg');
    //   xnew = -p.Y;
    //   ynew = p.X;
    // } else {
    xnew = p.X * c - p.Y * s;
    ynew = p.X * s + p.Y * c;
    // }
    // translate point back:
    p.X = xnew + centerPoint.X;
    p.Y = ynew + centerPoint.Y;
    return p;
  }

  export function translate(x: number, y: number, vec_x: number, vec_y: number) {
    return [x + vec_x, y + vec_y];
  }

  export function translate2(point: Point, vec: Vector): Point {
    return { X: point.X + vec.X, Y: point.Y + vec.Y };
  }

  export function scale(x: number, y: number, vec_x: number, vec_y: number) {
    return [x * vec_x, y * vec_y];
  }

  // export function scale2(point: Point, vec: Vector) {
  //   return { X: point.X * vec.X, Y: point.Y * vec.Y };
  // }
  export function scale2(vec: Vector, scale: number) {
    return { X: vec.X * scale, Y: vec.Y * scale };
  }
  export function scaleLine(line: Line, scale1: number, scale2: number): Line {
    let X1 = line.point1.X + (line.point2.X - line.point1.X) * scale1;
    let Y1 = line.point1.Y + (line.point2.Y - line.point1.Y) * scale1;

    let X2 = line.point1.X + (line.point2.X - line.point1.X) * scale2;
    let Y2 = line.point1.Y + (line.point2.Y - line.point1.Y) * scale2;
    return { point1: { X: X1, Y: Y1 }, point2: { X: X2, Y: Y2 } };
  }

  export function vectorPerpendicular(vec: Vector, leftOrRight = false) {
    //swap dimentions and negate one of the dimention
    if (leftOrRight) {
      return { X: vec.Y, Y: 0 - vec.X };
    } else {
      return { X: 0 - vec.Y, Y: vec.X };
    }
  }

  export function length(vec: Vector) {
    return Math.sqrt(Math.pow(vec.X, 2) + Math.pow(vec.Y, 2));
  }

  export function calcVectorAngle(vec1: Vector) {
    if (vec1.X === 0 && vec1.Y === 0) {
      return Number.NaN;
    } else if (vec1.X === 0) {
      return vec1.Y > 0 ? 90 : -90;
    } else if (vec1.Y === 0) {
      return vec1.X > 0 ? 0 : 180;
    }
    // return Math.atan(vec1.Y / vec1.X) * (180 / Math.PI);
    return Math.atan2(vec1.Y, vec1.X) * (180 / Math.PI);
    // Math.at
  }

  export interface LinkedPoint extends Point {
    prev?: Point;
    next?: Point;
  }

  export function calcAngleLinkedPoint(p: LinkedPoint) {
    let v1: Vector = vector(p.prev!, p);
    let v2: Vector = vector(p, p.next!);
    return (calcVectorAngle(v1) + calcVectorAngle(v2)) / 2;
    // return (180 + calcVectorAngle(v1) + calcVectorAngle(v2)) / 2;
  }

  export function clone(p1: Point): Point {
    return { X: p1.X, Y: p1.Y };
  }

  //scale X base on view aspect ratio!, this should be called after rotation
  export function viewRatio(point: Vector, ratio: number): Vector {
    // return { X: point.X, Y: point.Y * ratio };
    return { X: point.X, Y: point.Y };
  }
  export function inRange(n1: number, n2: number, val: Number) {
    let start = Math.min(n1, n2);
    let end = Math.max(n1, n2);
    return start <= val && val <= end;
  }

  // export function tanDeg(deg:Number){
  //  deg =mod_angle(deg);
  //   if(deg === 0){
  //     return 0;
  //   }
  //   else if(deg === 90){
  //     return
  //   }
  // }

  // export function mod_angle(angle: number) {
  //   let m = angle % 360;
  //   return m < 0 ? m + 360 : m;
  // }
}
