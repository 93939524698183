import { PanelPlugin, SetFieldConfigOptionsArgs } from '@grafana/data';
import { GeosteeringPanel } from './GeosteeringPanel';
import { GraphFieldConfig } from '@grafana/ui';
import { defaultGraphConfig, getGraphFieldConfig } from 'panels/log-plot-panel/copiedFromAlpha75/timeSeriesConfig';
import { LogPlotFieldConfig } from 'panels/log-plot-panel/module';
import { GeosteeringPanelOptions } from './types';

//TODO: duplicate with trackinfo in Calculation Utils, to be merged
export type TrackInfo = { Offset: number; TrackWidth: number; Position: 'Inner' | 'Outer' };
export interface GeosteeringPlotFieldConfig extends GraphFieldConfig {
  trackInfo: TrackInfo;
  trackId: number;
  // trackWidth: number;
  order: number;
  fontSize: number;
  nullAsZero: boolean;
  yHeight: number;
  showGridLines: boolean;
  location: 'FollowTrajectory' | 'Bottom' | 'Top';
}
export const defaultGeosteeringPlotFieldConfig: GeosteeringPlotFieldConfig = {
  ...defaultGraphConfig,
  trackId: 0,
  // trackWidth: 300,
  trackInfo: {
    TrackWidth: 300,
    Offset: 100,
    Position: 'Inner',
  },
  order: 0,
  fontSize: 16,
  nullAsZero: false,
  yHeight: 150,
  showGridLines: false,
  location: 'FollowTrajectory',
};

function getGeosteeringPlotFieldConfig(): SetFieldConfigOptionsArgs<GeosteeringPlotFieldConfig> {
  let fc = getGraphFieldConfig(defaultGeosteeringPlotFieldConfig as LogPlotFieldConfig);
  let oldUseCustomConf = fc.useCustomConfig!;
  fc.useCustomConfig = (builder) => {
    oldUseCustomConf(builder);
    builder.addNumberInput({
      path: 'trackInfo.TrackWidth',
      name: 'Track Width',
      defaultValue: defaultGeosteeringPlotFieldConfig.trackInfo.TrackWidth,
    });
    builder.addNumberInput({
      path: 'trackInfo.Offset',
      name: 'Track Offset',
      defaultValue: defaultGeosteeringPlotFieldConfig.trackInfo.Offset,
    });
    builder.addRadio({
      path: 'trackInfo.Position',
      name: 'Track Position',
      defaultValue: defaultGeosteeringPlotFieldConfig.trackInfo.Position,
      settings: {
        options: [
          {
            value: 'Inner',
            label: 'Inner',
          },
          {
            value: 'Outer',
            label: 'Outer',
          },
        ],
      },
    });
    // builder.addBooleanSwitch({
    //   path: 'trackInfo.Position',
    //   name: 'Track Position',
    //   defaultValue: defaultGeosteeringPlotFieldConfig.trackInfo.Position,
    // });
    // builder.addNumberInput({
    //   path: 'order',
    //   name: 'Order',
    //   defaultValue: defaultGeosteeringPlotFieldConfig.order,
    // });
    builder.addNumberInput({
      path: 'fontSize',
      name: 'Font Size',
      defaultValue: defaultGeosteeringPlotFieldConfig.fontSize,
    });
    builder.addBooleanSwitch({
      path: 'nullAsZero',
      name: 'Null As Zero',
      defaultValue: defaultGeosteeringPlotFieldConfig.nullAsZero,
    });

    builder.addBooleanSwitch({
      path: 'showGridLines',
      name: 'Show Grid Lines',
      defaultValue: defaultGeosteeringPlotFieldConfig.showGridLines,
    });

    builder.addNumberInput({
      path: 'yHeight',
      name: 'Height in Pixels',
      defaultValue: defaultGeosteeringPlotFieldConfig.yHeight,
    });

    builder.addSelect({
      path: 'location',
      name: 'location in Chart',
      settings: {
        options: [
          { value: 'FollowTrajectory', label: 'FollowTrajectory' },
          { value: 'Top', label: 'Top' },
          { value: 'Bottom', label: 'Bottom' },
        ],
      },
      defaultValue: defaultGeosteeringPlotFieldConfig.location,
    });
  };
  return fc as any;
}

export const plugin = new PanelPlugin<GeosteeringPanelOptions, GeosteeringPlotFieldConfig>(GeosteeringPanel)
  .useFieldConfig(getGeosteeringPlotFieldConfig())
  .setPanelOptions((builder) => {
    builder.addBooleanSwitch({
      path: 'tvdVSmd',
      name: 'TVD vs MD',
      // description: 'Hide Gridlines on Track',
      defaultValue: false,
    });
  });
