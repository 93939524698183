import { merge } from 'lodash';

const lithImgFiles = {
  Clay: require('../img/lithology/Clay.png'),
  Siltstone: require('../img/lithology/Siltstone.png'),
  Sandstone: require('../img/lithology/Sandstone.png'),
  Conglomerate: require('../img/lithology/Conglomerate.png'),
  Breccia: require('../img/lithology/Breccia.png'),
  Marlstone: require('../img/lithology/Marlstone.png'),
  Limeston: require('../img/lithology/Limestone.png'),
  Arg_Limestone: require('../img/lithology/Arg_Limestone.png'),
  Dolomitic_Limestone: require('../img/lithology/Dolomitic_Limestone.png'),
  Dolomite: require('../img/lithology/Dolomite.png'),
  Anhydrite: require('../img/lithology/Anhydrite.png'),
  Gypsum: require('../img/lithology/Gypsum.png'),
  Cement: require('../img/lithology/Cement.png'),
  Shale: require('../img/lithology/Shale.png'),
  Chert: require('../img/lithology/Chert.png'),
  Pebbly_Sand: require('../img/lithology/Pebbly_Sand.png'),
  Limestone_Dolomite: require('../img/lithology/Limestone_Dolomite.png'),
  LST_Chalky: require('../img/lithology/LST_Chalky.png'),
  No_Sample: require('../img/lithology/No_Sample.png'),
  Silty_Sandstone: require('../img/lithology/Silty_Sandstone.png'),
};

//KEC Lithology
const kecLithImages = {
  // LAMCLST: 缺少
  //LAMSST: 缺少
  CLST1: lithImgFiles.Clay,
  CLST2: lithImgFiles.Clay, //缺少，用Clay.png替代
  CLST3: lithImgFiles.Clay,
  SLST: lithImgFiles.Siltstone,
  SND: lithImgFiles.Sandstone,
  SSTF: lithImgFiles.Sandstone, //缺少,Fine Sand/Sandstone , Sandstone替代
  SSTM: lithImgFiles.Sandstone, //缺少, Medium Sand/Sandstone，暂时用Sandstone替代
  SSTC: lithImgFiles.Sandstone, //缺少, Coarse Sand/Sandstone，暂时用Sandstone替代
  CONGM: lithImgFiles.Conglomerate,
  CONGP: lithImgFiles.Conglomerate, //缺少Conglomerate (polygenic)，用Conglomerate替代
  BRECM: lithImgFiles.Breccia,
  BRECP: lithImgFiles.Breccia, //Breccia (polygenic)，用Breccia替代
  MARL: lithImgFiles.Marlstone,
  // COAL://待添加！
  LST: lithImgFiles.Limeston,
  LST1: lithImgFiles.Arg_Limestone,
  // LST2: //Silty Limestone缺少,是否是Lst compact致密灰岩？
  // LST3://Lst Sandy待添加
  // LST4://Oolitic Limestone 缺少
  // CHALK:// chalk缺少, 用LST_Chalky 替代
  CHALK: lithImgFiles.LST_Chalky,
  DOLLST: lithImgFiles.Dolomitic_Limestone,
  // SILEXITE: //缺少
  DOL: lithImgFiles.Dolomite,
  // CALCDOL:// 缺少Calcerous Dolomite， 钙化白云岩？
  ANH: lithImgFiles.Anhydrite,
  GYP: lithImgFiles.Gypsum,
  // HAL://Halite缺少
  // SYLV	Sylvite缺少
  //TUFF	Tuff缺少
  //VOLC	Volcanics缺少
  //INTRUSIVE	Intrusive Igneous缺少
  //BASEMENT	Basement缺少
  CEMENT: lithImgFiles.Cement,
  // UKNOWN	Uknown缺少
  // NO SAMPLE	No Sample
  // SEA	Sea
  SHALE: lithImgFiles.Shale,
  CHERT: lithImgFiles.Chert,

  // LST2: require('../../../img/lithology/Limestone_Dolomite.png'),
  LSTDOL: lithImgFiles.Limestone_Dolomite,
  // CLST1: require('../../../img/lithology/Clay.png'),
  CHTY: lithImgFiles.Chert, //note: same name, alias?
  CLAY: lithImgFiles.Clay,
  CLAYSTONE: lithImgFiles.Clay,
  // CHERT: require('../../../img/lithology/Chert.png'),
};

//二号服务器岩性, Daqing
const lithImageDic2 = {
  Anhydrite: lithImgFiles.Anhydrite,
  'Arg Limestone': lithImgFiles.Arg_Limestone,
  Arg_Limestone: lithImgFiles.Arg_Limestone,
  Breccia: lithImgFiles.Breccia,
  Cement: lithImgFiles.Cement,
  Chert: lithImgFiles.Chert,
  Clay: lithImgFiles.Clay,
  Claystone: lithImgFiles.Clay,
  Conglomerate: lithImgFiles.Conglomerate,
  Dolomite: lithImgFiles.Dolomite,
  'Dolomitic Limestone': lithImgFiles.Dolomitic_Limestone,
  'Dolo Limestone': lithImgFiles.Dolomitic_Limestone,
  Gypsum: lithImgFiles.Gypsum,
  'Limestone Dolomite': lithImgFiles.Limestone_Dolomite,
  Limestone: lithImgFiles.Limeston,
  'LST Chalky': lithImgFiles.LST_Chalky,
  Marlstone: lithImgFiles.Marlstone,
  'No Sample': lithImgFiles.No_Sample,
  No_Sample: lithImgFiles.No_Sample,
  'Pebbly Sand': lithImgFiles.Pebbly_Sand,
  Pebbly_Sand: lithImgFiles.Pebbly_Sand,
  Sandstone: lithImgFiles.Sandstone,
  Shale: lithImgFiles.Shale,
  Siltstone: lithImgFiles.Siltstone,
  'Silty Sandstone': lithImgFiles.Silty_Sandstone,
};

export const lithImageDic = merge(kecLithImages, lithImageDic2);

export const qualifierDic = {
  ARG: require('../img/lithology/qualifier/Argillaceous.png'),
  SLTY: require('../img/lithology/qualifier/Silty.png'),
  SNDY: require('../img/lithology/qualifier/Sandy.png'),
  // SNDYC: require('../../../img/lithology/qualifier/Sandy.png'), //note: not found
  CHTY: require('../img/lithology/qualifier/ChertLight.png'), //note: dark or light?
  CALC: require('../img/lithology/qualifier/Calcareous.png'),
  CHKY: require('../img/lithology/qualifier/ChertDark.png'), //note: not found
  GLAUC: require('../img/lithology/qualifier/Glauconite.png'),
  // MIC: require('../../../img/lithology/qualifier/.png'), //not found
  PYR: require('../img/lithology/qualifier/Pyrite.png'),
  CARB: require('../img/lithology/qualifier/Carbonaceous.png'),
  BIT: require('../img/lithology/qualifier/Bituminous.png'),
  FE: require('../img/lithology/qualifier/Ferruginous.png'),
  //NOTE: the original file is renamed with postfix _qualifier just to avoid webpack naming clash during build time
  ANHY: require('../img/lithology/qualifier/Anhydrite_qualifier.png'),
  SALT: require('../img/lithology/qualifier/Salt.png'),
  FLD: require('../img/lithology/qualifier/Feldspar.png'),
  OOL: require('../img/lithology/qualifier/Oolitic.png'),
  SHLFRAG: require('../img/lithology/qualifier/Shells.png'),
  MICFOS: require('../img/lithology/qualifier/Microfossils.png'),
  NUMM: require('../img/lithology/qualifier/Nodules.png'),

  MARL: require('../img/lithology/qualifier/Marly.png'),
  SHALE: require('../img/lithology/qualifier/ShaleLaminae.png'),
  CAL: require('../img/lithology/qualifier/Calcareous.png'), //note: duplicated?
  DOL: require('../img/lithology/qualifier/Dolomitic.png'), //note: duplicated?
};

export const lithImages = Object.entries(lithImageDic)
  .map((v) => {
    let k = v[0] as keyof typeof lithImageDic;
    let img = new Image();
    img.src = v[1];
    return { k, img };
  })
  .reduce((accu, item) => {
    accu[item.k] = item.img;
    return accu;
  }, {} as Record<keyof typeof lithImageDic, HTMLImageElement>);
