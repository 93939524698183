import React, { useEffect, useState } from 'react';
import { GrafanaTheme, PanelProps } from '@grafana/data';
import { GeosteeringPanelOptions } from './types';
import ReactECharts from 'echarts-for-react';
import { ECharts } from 'echarts';
import { GeosteeringOption } from './GeosteeringOption';
import { HorizontalGroup, Label, Slider, ToolbarButton, VerticalGroup, withTheme } from '@grafana/ui';
import { debounce } from 'lodash';

interface Props extends PanelProps<GeosteeringPanelOptions> {
  theme: GrafanaTheme;
}

const PartialGeosteeringPanel: React.FC<Props> = ({ options, data, width, height, onOptionsChange, theme }) => {
  // export const GeosteeringPanel: React.FC<Props> = (ops) => {

  // if (options.text !== 'hi ching') {
  //   onOptionsChange({ ...options, text: 'hi ching' });
  // }
  // const [loading, setLoading] = useState(false);
  const [echartInst, setEchartInst] = useState<ECharts | undefined>(undefined);
  const [geosteeringOption, setGeosteeringOption] = useState<GeosteeringOption | undefined>(undefined);
  const [autoAziViewAngle, setAutoAziViewAngle] = useState(options.autoAzimuthViewAngle);
  const [aziViewAngle, setAziViewAngle] = useState(options.autoAzimuthViewAngle ? 0 : options.azimuthViewAngle);

  useEffect(() => {
    if (autoAziViewAngle) {
      let azi = options.azimuthViewAngle;

      let trajSeries = data.series.find((s) => s.meta?.custom!['dataObjectType'] === 'Trajectory')!;
      let aziField = trajSeries.fields.find((f) => f.name === 'azi');
      // let azVertSec: number | undefined = undefined;
      if (aziField !== undefined && aziField.values.length > 0) {
        azi = aziField.values.get(aziField.values.length - 1) as number;
        setAziViewAngle(azi);
      }
    }
  }, [autoAziViewAngle, aziViewAngle, setAziViewAngle, data]);

  const [enableZoom, setEnableZoom] = useState(options.enableZoom);
  const [enablePan, setEnablePan] = useState(options.enablePanning);

  // useEffect(() => {
  // let trajSeries = data.series.find((s) => s.meta?.custom!['dataObjectType'] === 'Trajectory')!;
  // let aziField = trajSeries.fields.find((f) => f.name === 'azi');
  // let azVertSec: number | undefined = undefined;
  // if (azVertSec === undefined && aziField !== undefined && aziField.values.length > 0) {
  //   azVertSec = aziField.values.get(aziField.values.length - 1) as number;
  //   if (aziViewAngle !== azVertSec!) {
  //     setAziViewAngle(() => azVertSec!);
  //     alert(azVertSec);
  //   }
  // }
  // }, [data, aziViewAngle, setAziViewAngle]);

  // useEffect(() => {
  //   setAziViewAngle(() => 100);
  // }, [aziViewAngle, setAziViewAngle]);

  useEffect(() => {
    if (echartInst !== undefined && geosteeringOption !== undefined) {
      // geosteeringOption.setData(data, true, aziViewAngle);
      geosteeringOption.setData(data, true, aziViewAngle);
    }
    // setLoading(data.state === LoadingState.Loading ? true : false);
  }, [echartInst, data, geosteeringOption, aziViewAngle]);
  const dbouncedResize = debounce(
    () => {
      geosteeringOption?.resetAspectRatioToOne2();
      geosteeringOption?.setZoomMaxSpan();
    },
    500,
    { trailing: true, leading: false }
  );
  useEffect(() => {
    dbouncedResize();
    // console.log('resizing!!!!');
  }, [width, height]);

  useEffect(() => {
    geosteeringOption?.setEnableZoomingPanning(enableZoom, enablePan);
  }, [enableZoom, enablePan, geosteeringOption]);
  return (
    <VerticalGroup>
      <HorizontalGroup>
        <ToolbarButton
          key="btnZoom"
          icon="search-plus"
          tooltip={enableZoom ? 'Disable Zooming' : 'Enable Zooming'}
          variant={enableZoom ? 'active' : 'default'}
          onClick={() => {
            let zoomEnabled = !enableZoom;
            setEnableZoom(zoomEnabled);
            onOptionsChange({ ...options, enableZoom: zoomEnabled });
          }}
        ></ToolbarButton>
        <ToolbarButton
          key="btnPann"
          // icon="expand-arrows-alt"
          icon={
            <svg widths="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M278.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-64 64c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l9.4-9.4V224H109.3l9.4-9.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-64 64c-12.5 12.5-12.5 32.8 0 45.3l64 64c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-9.4-9.4H224V402.7l-9.4-9.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l64 64c12.5 12.5 32.8 12.5 45.3 0l64-64c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-9.4 9.4V288H402.7l-9.4 9.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l64-64c12.5-12.5 12.5-32.8 0-45.3l-64-64c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l9.4 9.4H288V109.3l9.4 9.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-64-64z" />
            </svg>
          }
          tooltip={enablePan ? 'Disable Panning' : 'Enable Panning'}
          variant={enablePan ? 'active' : 'default'}
          onClick={() => {
            let panEnabled = !enablePan;
            setEnablePan(panEnabled);
            onOptionsChange({ ...options, enablePanning: panEnabled });
          }}
        ></ToolbarButton>

        <ToolbarButton
          key="btnAutoViewAngle"
          icon="process"
          tooltip={autoAziViewAngle ? 'Disable Automatic Azi' : 'Automatic Azi'}
          variant={autoAziViewAngle ? 'active' : 'default'}
          onClick={() => {
            let autoAziViewAngle2 = !autoAziViewAngle;
            setAutoAziViewAngle(autoAziViewAngle2);
            onOptionsChange({ ...options, autoAzimuthViewAngle: autoAziViewAngle2 });
          }}
        ></ToolbarButton>

        {!options.tvdVSmd && (
          <>
            <Label>Azimuth View Angle: </Label>
            <div style={{ width: 300 }}>
              {autoAziViewAngle ? (
                <Label>{aziViewAngle.toFixed(1)} dega</Label>
              ) : (
                <Slider
                  min={0}
                  max={360}
                  value={aziViewAngle}
                  onChange={(el) => {
                    let azi = el ?? 0;
                    setAziViewAngle(azi);
                    onOptionsChange({ ...options, azimuthViewAngle: azi });
                  }}
                ></Slider>
              )}
            </div>
          </>
        )}
      </HorizontalGroup>
      <ReactECharts
        option={{}}
        style={{ width, height }}
        theme={theme.isDark ? 'dark' : undefined}
        // showLoading={loading}
        onChartReady={(inst) => {
          setEchartInst(inst);
          setGeosteeringOption(new GeosteeringOption(inst, data, options, onOptionsChange, theme));
        }}
      />
    </VerticalGroup>
  );
};

export const GeosteeringPanel = withTheme(PartialGeosteeringPanel);
