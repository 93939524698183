//md: measured depth
//tvd: true vertical depth
//Incl: Hole inclination, measured from vertical 垂直倾斜度， 单位为度数
//Azi: Hole azimuth. Corrected to wells azimuth reference 方位角， 北向顺时针测量
//dispNs:  南北向位移，北向为正数

// North-south offset, positive to the North.
// This is relative to wellLocation with a North axis orientation of aziRef.
// If a displacement with respect to a different point is desired then
// define a localCRS and specify local coordinates in location.

// DispEw

// Dls， Dogleg severity. 拐弯程度： 单位为 dega/100ft
// vertical section:  Distance along vertical section azimuth plane. 与垂直方位角测量平面的距离

//mtf, Toolface angle (magnetic). 数值为空
//gtf, Toolface angle (gravity).  数值为空

import { DataFrame, Field, FieldConfig, FieldType, MutableVector, PanelData } from '@grafana/data';
import { DatasetComponentOption } from 'echarts';
import { GeologyInterval } from 'lib/mudLogDataFrame';
import { MathUtils } from './mathUtil';
import { GeosteeringData } from './models/GeosteeringData';
import { GeosteeringPlotFieldConfig } from '../panels/geosteering/module';

const PhiFactor = 180 / Math.PI;
const degaToRadFactor = Math.PI / 180.0;

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace TrajectoryCalculationUtility {
  // export const OFFSET = -100; //meters in vertical section
  // export const TRACK_WIDTH = -200; //meters in vertical section
  export type TrackInfo = { Offset: number; TrackWidth: number };
  export const TRACK_INFO: TrackInfo = { Offset: -100, TrackWidth: -300 };

  // export const Radian90Degree = 1.570796327;
  export const Radian90Degree = Math.PI / 2;
  export function ConvertRadianToDegree(radian: number) {
    return radian * PhiFactor;
  }
  export function ConvertDegreeToRadian(degree: number) {
    return degree * degaToRadFactor;
  }

  export function CalculateVerticalSection(azVertSec: number, dispEw: number, dispNs: number, tvdVSmd = false) {
    if (Number.isNaN(dispEw) || Number.isNaN(dispNs)) {
      return 0;
    }

    let azimuthClosureDirection = Math.atan(dispEw / dispNs) * PhiFactor;
    azimuthClosureDirection = Number.isNaN(azimuthClosureDirection) ? 0 : azimuthClosureDirection;

    if (dispNs < 0 && dispEw > 0) {
      azimuthClosureDirection = azimuthClosureDirection + 180;
    } else if (dispNs < 0 && dispEw < 0) {
      azimuthClosureDirection = azimuthClosureDirection + 180;
    } else if (dispNs > 0 && dispEw < 0) {
      azimuthClosureDirection = azimuthClosureDirection + 360;
    }

    let vertSec =
      Math.cos((Math.PI * (azVertSec - azimuthClosureDirection)) / 180) *
      Math.sqrt(Math.pow(dispEw, 2) + Math.pow(dispNs, 2));

    return vertSec;
  }

  export function TransformDataset(data: PanelData, azVertSec: number, tvdVSmd = false) {
    let trajSeries = data.series.find((s) => s.meta?.custom!['dataObjectType'] === 'Trajectory');
    let logs = data.series.filter((s) => s.meta?.custom!['dataObjectType'] === 'Log');
    let mudLogs = data.series.filter((s) => s.meta?.custom!['dataObjectType'] === 'MudLog');

    if (trajSeries === undefined) {
      return;
    }

    //prediction, extend trajectory to the latest md
    let mdOfLogs = logs.map((l) => {
      let fieldMd = l.fields.find((f) => f.name === 'depth')!;
      let fieldBitDepth = l.fields.find((f) => f.name === 'BITDEPTH')!;
      // console.log('fieldMd');

      if (fieldMd === undefined || fieldBitDepth === undefined) {
        return 0;
      }
      let md = fieldMd.values.get(fieldMd.values.length - 1);
      let bitDepth = fieldBitDepth.values.get(fieldBitDepth.values.length - 1);
      console.log({ md, bitDepth });

      return Math.max(md, bitDepth);
    });
    let maxMd = Math.max(...mdOfLogs);
    // console.log('maxMd');
    // console.log(maxMd);
    // console.log(trajSeries);
    PredictToMd(maxMd, trajSeries);
    //calculate vertical section
    // let depthField = trajSeries.fields.find((f) => f.name.toLowerCase().trim() === 'depth')!;
    // let tvdField = trajSeries.fields.find((f) => f.name.toLowerCase().trim() === 'tvd')!;
    // let inclField = trajSeries.fields.find((f) => f.name.toLowerCase().trim() === 'incl')!;
    // let aziField = trajSeries.fields.find((f) => f.name.toLowerCase().trim() === 'azi')!;
    // let dlsField = trajSeries.fields.find((f) => f.name.toLowerCase().trim() === 'dls')!;

    let dispNsField = trajSeries.fields.find((f) => f.name.toLowerCase().trim() === 'dispns')!;
    let dispEwField = trajSeries.fields.find((f) => f.name.toLowerCase().trim() === 'dispew')!;
    let mdField = trajSeries.fields.find((f) => f.name.toLowerCase().trim() === 'md')!;
    let vertSectField = trajSeries.fields.find((f) => f.name.toLowerCase().trim() === 'vertsect')!;
    let vertSectVector = vertSectField.values as MutableVector;
    // vertSectVector

    dispNsField.values.toArray().map((v, i) => {
      let dispNs = v;
      let dispEw = dispEwField.values.get(i);
      let vertSect = tvdVSmd ? mdField.values.get(i) : CalculateVerticalSection(azVertSec, dispEw, dispNs, tvdVSmd);
      (vertSectField.values as MutableVector).add(vertSect);
      if (vertSectVector.get(i) !== undefined) {
        vertSectVector.set(i, vertSect);
      } else {
        vertSectVector.add(vertSect);
      }
    });

    //now convert traject series to dataset
    let trajDataset = frameToDataset(trajSeries);

    let dataTable = transformColumnarToRows(trajDataset);
    let dict = GeosteeringData.TrajectoryDataPointDictionary.LoadFromDataTable(dataTable, maxMd);
    console.log('dict');
    console.log(dict);
    let logsDatasets = logs.map((l) => {
      let ds = frameToDataset(l);
      return transformDataset(l, ds, dict, tvdVSmd);
    });

    let mudLogDatasets = mudLogs.map((l) => {
      let ds = frameToDataset(l);
      return transformLithologyDataset(l, ds, dict);
    });

    console.log([...logsDatasets, ...mudLogDatasets, trajDataset]);
    return { datasets: [...logsDatasets, ...mudLogDatasets, trajDataset], trajectoryDict: dict };
  }

  function PredictToMd(maxMd: number, trajSeries: DataFrame) {
    let depthField = trajSeries.fields.find((f) => f.name.toLowerCase().trim() === 'depth')!;
    let lastDepthIdx = depthField.values.length - 1;
    let lastDepth = depthField.values.get(lastDepthIdx);
    if (maxMd > lastDepth) {
      let scale =
        (maxMd - depthField.values.get(lastDepthIdx - 1)) /
        (depthField.values.get(lastDepthIdx) - depthField.values.get(lastDepthIdx - 1));

      trajSeries.fields.forEach((f) => {
        let predictedVal =
          (f.values.get(lastDepthIdx) - f.values.get(lastDepthIdx - 1)) * scale + f.values.get(lastDepthIdx - 1);
        let values = f.values as MutableVector;
        values.add(predictedVal);
      });
    }

    return trajSeries;
  }

  declare type DimensionDefinition = {
    type?: 'time' | 'ordinal' | 'number';
    name?: string;
    displayName?: string;
  };

  ///optionally transform data as the trajectory.
  function frameToDataset(s: DataFrame) {
    let dataset: DatasetComponentOption = {};
    let dimensions: DimensionDefinition[] = s.fields.map((f, i) => {
      if (f.type === FieldType.time) {
        return { name: f.name, type: 'time' };
      } else if (f.type === FieldType.string) {
        return { name: f.name, type: 'ordinal' };
      } else if (f.type === FieldType.number) {
        return { name: f.name, type: 'number' };
      } else {
        return { name: f.name, type: 'ordinal' };
      }
    });

    let columar = s.fields.map((f) => f.values.toArray());

    dataset.dimensions = dimensions;
    dataset.source = columar;
    dataset.name = s.meta?.custom?.etpUrl;
    dataset.id = s.meta?.custom?.etpUrl;
    // console.log('data frame meta');
    // console.log(s.meta?.custom);

    return dataset;
  }

  function transformDataset(
    dataFrame: DataFrame,
    originalDataset: DatasetComponentOption,
    traj: GeosteeringData.TrajectoryDataPointDictionary,
    tvdVSmd = false
  ): DatasetComponentOption {
    //follow the trajectory will transform the values to it's coordinate system.
    //add ${dimention.name}_transformed and ${dimention.name}_transformed_tvd to the dataset

    let dimensions: DimensionDefinition[] = originalDataset.dimensions! as DimensionDefinition[];
    let source: any[][] = originalDataset.source! as any[][];
    // let tvdIndex = dimensions.findIndex((d) => d.name === 'tvd');
    // let tvdIndex = dimensions.findIndex((d) => d.name === 'VDEPTH');
    let mdIndex = dimensions.findIndex((d) => d.name === 'depth');
    // let md2Index = dimensions.findIndex((d) => d.name === 'md');
    // let tvdSeries = source[tvdIndex];
    let mdSeries = source[mdIndex];

    let indexDimensions = ['md', 'index', 'tvd', 'depth'];
    let indexDimensionsIndex = indexDimensions.map((idi) => dimensions.findIndex((d) => d.name === idi));

    let newDimensions = dimensions
      .map((d) => {
        if (indexDimensions.indexOf(d.name!) >= 0) {
          return [d];
        } else {
          let d_transformed: DimensionDefinition = { ...d, name: `${d.name}_transformed` };
          let d_transformed_tvd: DimensionDefinition = { ...d, name: `${d.name}_transformed_tvd`, type: 'number' };
          return [d, d_transformed, d_transformed_tvd];
        }
      })
      .flat();

    let newSource = source
      .map((fieldSeries, i) => {
        // console.log(fieldSeries);
        let field = dataFrame.fields[i];
        if (indexDimensionsIndex.indexOf(i) >= 0) {
          return [fieldSeries];
        } else {
          let v_transformed: any[] = [];
          let v_transformed_tvd: any[] = [];
          let v: any[] = [];

          fieldSeries.forEach((dataPoint, j) => {
            let result = FollowTrajectory3(
              traj,
              // tvdSeries === undefined ? Number.NaN : tvdSeries[j],
              Number.NaN,
              mdSeries[j],
              dataPoint,
              field
            );
            // let result = FollowTrajectory3(traj, Number.NaN, mdSeries[j], dataPoint, field);
            v.push(result.val);
            v_transformed.push(result.vPoint.X);
            v_transformed_tvd.push(result.vPoint.Y);
          });
          return [v, v_transformed, v_transformed_tvd];
        }
      })
      .flat();

    return { id: originalDataset.id, name: originalDataset.name, dimensions: newDimensions, source: newSource };
  }

  function transformLithologyDataset(
    dataFrame: DataFrame,
    originalDataset: DatasetComponentOption,
    traj: GeosteeringData.TrajectoryDataPointDictionary
  ): DatasetComponentOption {
    let dimensions: DimensionDefinition[] = originalDataset.dimensions! as DimensionDefinition[];
    let source: any[][] = originalDataset.source! as any[][];
    let indexDimensions = ['md', 'index', 'tvd', 'depth'];

    let geoIntvField = dataFrame.fields.filter((f) => indexDimensions.indexOf(f.name) < 0)[0];

    source[1] = geoIntvField.values.toArray().map((geoIntv: GeologyInterval) => {
      let topPoint1 = FollowTrajectory3(traj, Number.NaN, geoIntv.mdTop, 0, geoIntvField).vPoint;
      let topPoint2 = FollowTrajectory3(traj, Number.NaN, geoIntv.mdTop, 100, geoIntvField).vPoint;

      let bottomPoint1 = FollowTrajectory3(traj, Number.NaN, geoIntv.mdBottom, 0, geoIntvField).vPoint;
      let bottomPoint2 = FollowTrajectory3(traj, Number.NaN, geoIntv.mdBottom, 100, geoIntvField).vPoint;
      geoIntv.shape = {
        lineTop: {
          point1: topPoint1,
          point2: topPoint2,
        },
        lineBottom: {
          point1: bottomPoint1,
          point2: bottomPoint2,
        },
      };
      return geoIntv;
    });

    return { id: originalDataset.id, name: originalDataset.name, dimensions, source };
  }

  function FollowTrajectory3(
    trajectData: GeosteeringData.TrajectoryDataPointDictionary,
    tvd: number,
    md: number,
    val: number,
    field: Field,
    // vMin = 0,
    // vMax = 50,
    // trackInfo = TRACK_INFO,
    trackNumber = 0
  ) {
    let c = field.config as FieldConfig<GeosteeringPlotFieldConfig>;
    let trackInfo = c.custom?.trackInfo!;
    if (trackInfo.Position === 'Outer') {
      trackInfo = {
        TrackWidth: -trackInfo.TrackWidth,
        Offset: -trackInfo.Offset,
        Position: trackInfo.Position,
      };
    }

    let xMin = trackInfo.Offset * (trackNumber + 1);
    let xMax = xMin + trackInfo.Offset + trackInfo.TrackWidth;
    let vMin = field.config.min ?? 0;
    let vMax = field.config.max ?? 100;
    let perc = (val - vMin) / (vMax - vMin);
    let vInX = xMin + perc * (xMax - xMin);
    // let vTransformed = val;
    let section = trajectData.GetTrajectorySection(md);

    // vTransformed = -val;
    let vPoint: MathUtils.Point = { X: val, Y: tvd };

    if (section.trajectoryPoint1 && section.trajectoryPoint2) {
      let trajPoint1 = section.trajectoryPoint1.GetPoint();
      let trajPoint2 = section.trajectoryPoint2.GetPoint();
      let vectorTraj = MathUtils.vector(trajPoint1, trajPoint2);
      let scale = (md - section.trajectoryPoint1.md) / (section.trajectoryPoint2.md - section.trajectoryPoint1.md);
      //if TVD is not available in the log, calculate it
      if (Number.isNaN(tvd)) {
        tvd = section.trajectoryPoint1.tvd + (section.trajectoryPoint2.tvd - section.trajectoryPoint1.tvd) * scale;
        vPoint.Y = tvd;
        // console.log(`calculating tvd: ${tvd}`);
      }

      let hitPoint = MathUtils.translate2(trajPoint1, MathUtils.scale2(vectorTraj, scale));
      // console.log({ trajPoint1, hitPoint, trajPoint2 });
      let hitPointRotate =
        section.trajectoryPoint2.angleInfo?.angleInDegree! - section.trajectoryPoint1.angleInfo?.angleInDegree!;
      // hitPointRotate = hitPointRotate * 2;

      let vectorTrajPerpendicular = MathUtils.vectorPerpendicular(vectorTraj, true);
      vectorTrajPerpendicular = MathUtils.scale2(
        vectorTrajPerpendicular,
        vInX / MathUtils.length(vectorTrajPerpendicular)
      );

      vPoint = MathUtils.rotate2(hitPoint, vectorTrajPerpendicular, hitPointRotate);

      vPoint = MathUtils.translate2(hitPoint, vectorTrajPerpendicular);
    }
    //  else {
    //   //TODO: this is a temporary fix!, predict trajectory base on the last few points!
    //   return { val: Number.NaN, vPoint: { X: Number.NaN, Y: Number.NaN } };
    // }

    // let tvdTransformed = vPoint.Y;
    // let vTransformed = vPoint.X;

    // let rotate1 = MathUtils.
    return { val, vPoint };
  }

  function transformColumnarToRows(ds: DatasetComponentOption) {
    //transform dataset to this format:
    // 38: {depth: 998.03, tvd: 993.79, incl: 19.9, azi: 216.5, dispNs: -26.44, …}
    // 39: {depth: 1026.29, tvd: 1020.12, incl: 22.62, azi: 216.67, dispNs: -34.66, …}
    // 40: {depth: 1054.9, tvd: 1046.25, incl: 25.48, azi: 217.08, dispNs: -43.99, …}
    // 41: {depth: 1082.88, tvd: 1071.23, incl: 28.04, azi: 217.21, dispNs: -54.03, …}
    // 42: {depth: 1111.42, tvd: 1096.09, incl: 30.76, azi: 216.9, dispNs: -65.21, …}
    // 43: {depth: 1140.03, tvd: 1120.3001, incl: 33.68, azi: 217.52, dispNs: -77.36, …}
    // 44: {depth: 1168.87, tvd: 1143.98, incl: 35.91, azi: 218.05, dispNs: -90.36, …}

    let data = ds.source! as any[][];
    let dataTable = data[0].map((col, i) => {
      let row: Record<string, any> = {};
      return ds.dimensions!.reduce((prev, current: any, j) => {
        prev[current.name] = data[j][i];
        return prev;
      }, row);
    });

    return dataTable;
  }
}
