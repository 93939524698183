import { Field } from '@grafana/data';

//   //#region   utilities
//   export getColorPalette(f: Field) {
//     // f.config;
//     let index = Object.values(this.wells)
//       .flatMap((w) => w.tracks)
//       .flatMap((t) => t.fields)
//       .indexOf(f);
//     // let index = (f as any).seriesIndex as number;
//     return f.config.color?.fixedColor || COLOR_PALETTE[index % (COLOR_PALETTE.length - 1)];
//   }
//    _color_dic: string[] = [];
//    getColorPalette2(s: string) {
//     if (_color_dic.indexOf(s) < 0) {
//       _color_dic.push(s);
//     }
//     return COLOR_PALETTE[_color_dic.indexOf(s) % (COLOR_PALETTE.length - 1)];
//   }

const COLOR_PALETTE = [
  'Black',
  '#5470c6',
  '#91cc75',
  '#fac858',
  '#ee6666',
  '#73c0de',
  '#3ba272',
  '#fc8452',
  '#9a60b4',
  '#ea7ccc',
  'Brown',
  'MidnightBlue',
  'Cyan',
  'Green',
  'Red',
  'Purple',
  'DeepPink',
];

let _color_dic: string[] = [];
export function getColorPalette2(s: string) {
  if (_color_dic.indexOf(s) < 0) {
    _color_dic.push(s);
  }
  return COLOR_PALETTE[_color_dic.indexOf(s) % (COLOR_PALETTE.length - 1)];
}

export function getColorPalette(f: Field, index: number) {
  if (f.config.color !== undefined) {
    return f.config.color!.fixedColor;
  } else {
    return COLOR_PALETTE[index % COLOR_PALETTE.length];
  }
}
