/* eslint-disable */

import _ from 'lodash';
import { TrajectoryCalculationUtility } from '../TrajectoryCalculationUtility';
import { MathUtils } from '../mathUtil';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace GeosteeringData {
  enum CurvePositions {
    Outer,
    Inner,
  }
  /* eslint-disable no-eval */
  // eslint-disable-next-line no-use-before-define
  interface ITrajectoryStationData {
    originIndex: number;
    tvd: number;
    // Tvdss: number;
    md: number;
    vertSect: number;
    dispNs: number;
    dispEw: number;
    azi: number;
    incl: number;
    dls: number;
    radius: number;
    additionRadiusTop: number;
    additionRadiusBottom: number;
    angleDip: number;
  }

  // eslint-disable-next-line no-use-before-define
  interface ILinkedObject<T> {
    prev?: T;
    next?: T;
  }

  interface Point {
    X: number;
    Y: number;
  }
  // eslint-disable-next-line no-use-before-define
  // interface ILogDataPoint extends Point {
  //   md: number;
  //   tvd: number;
  //   value: number;
  //   mnemonic: string;
  // }

  class AngleInfo {
    angleInDegree: number = Number.NaN;
    sinTheta: number = Number.NaN;
    cosTheta: number = Number.NaN;
  }

  class DataPoint {
    index: number = Number.NaN;
    angleInfo?: AngleInfo;
    perpendicularAngleInfo?: AngleInfo;
    isPredicted: boolean = false;
  }

  export class TrajectoryStationDataPoint
    extends DataPoint
    implements ITrajectoryStationData, ILinkedObject<TrajectoryStationDataPoint>
  {
    originIndex = Number.NaN;
    tvd = Number.NaN;
    md = Number.NaN;
    vertSect = Number.NaN;
    dispNs = Number.NaN;
    dispEw = Number.NaN;
    azi = Number.NaN;
    incl = Number.NaN;
    dls = Number.NaN;
    radius = Number.NaN;
    additionRadiusTop = Number.NaN;
    additionRadiusBottom = Number.NaN;
    angleDip = Number.NaN;

    prev?: TrajectoryStationDataPoint;
    next?: TrajectoryStationDataPoint;
    /**
     *
     */
    constructor(index: number) {
      super();
      this.md = index;
      this.index = index;
      this.originIndex = index;
    }

    GetPoint(): Point {
      return { X: this.vertSect, Y: this.tvd };
    }

    RecalculateVerticalSection(azimuthVerticalSection: number) {}
  }

  export class TrajectoryDataPointDictionary {
    private constructor() {}
    readonly dataPointsDict: Record<number, TrajectoryStationDataPoint> = {};
    readonly dataPointsArray: TrajectoryStationDataPoint[] = [];

    static LoadFromDataTable(dataTable: any[], maxMd: number): TrajectoryDataPointDictionary {
      // data table of format below:
      // 38: {depth: 998.03, tvd: 993.79, incl: 19.9, azi: 216.5, dispNs: -26.44, …}
      // 39: {depth: 1026.29, tvd: 1020.12, incl: 22.62, azi: 216.67, dispNs: -34.66, …}
      // 40: {depth: 1054.9, tvd: 1046.25, incl: 25.48, azi: 217.08, dispNs: -43.99, …}
      // 41: {depth: 1082.88, tvd: 1071.23, incl: 28.04, azi: 217.21, dispNs: -54.03, …}
      // 42: {depth: 1111.42, tvd: 1096.09, incl: 30.76, azi: 216.9, dispNs: -65.21, …}
      // 43: {depth: 1140.03, tvd: 1120.3001, incl: 33.68, azi: 217.52, dispNs: -77.36, …}
      // 44: {depth: 1168.87, tvd: 1143.98, incl: 35.91, azi: 218.05, dispNs: -90.36, …}
      dataTable = dataTable.sort((row) => row.depth);
      // let lastPoint = dataTable[dataTable.length - 1];

      let dict = new TrajectoryDataPointDictionary();
      let current: TrajectoryStationDataPoint | undefined = undefined;

      for (let i = 0; i < dataTable.length; i++) {
        const row = dataTable[i];
        let d = this.rowToDataPoint(row);

        if (current) {
          current.next = d;
        }
        d.prev = current;
        current = d;

        dict.dataPointsDict[row['depth']] = d;
        dict.dataPointsArray.push(d);
      }

      dict.dataPointsArray.forEach((p) => TrajectoryDataPointDictionary.CalculateAngleInfo(p));
      return dict;
    }

    // private GetExistingOrCreateDataPoint(index: number) {
    //   let point = this.dataPointsDict[index];
    //   if (point === undefined) {
    //     point;
    //   }
    // }

    GetTrajectorySection(md: number) {
      let trajectoryPointPosition = this.dataPointsArray.findIndex((t) => t.md >= md);
      let trajectoryPoint1 =
        trajectoryPointPosition < 1 ? this.dataPointsArray[0] : this.dataPointsArray[trajectoryPointPosition - 1];
      let trajectoryPoint2 = this.dataPointsArray[trajectoryPointPosition];
      return { trajectoryPoint1, trajectoryPoint2 };
    }

    GetTrajectorySection2(md: number) {
      let section = this.GetTrajectorySection(md);

      if (section.trajectoryPoint1 && section.trajectoryPoint2) {
        let trajPoint1 = section.trajectoryPoint1.GetPoint();
        let trajPoint2 = section.trajectoryPoint2.GetPoint();
        let vectorTraj = MathUtils.vector(trajPoint1, trajPoint2);
        let scale = (md - section.trajectoryPoint1.md) / (section.trajectoryPoint2.md - section.trajectoryPoint1.md);
        //if TVD is not available in the log, calculate it

        let hitPoint = MathUtils.translate2(trajPoint1, MathUtils.scale2(vectorTraj, scale));
        // console.log({ trajPoint1, hitPoint, trajPoint2 });
        let hitPointRotate =
          section.trajectoryPoint2.angleInfo?.angleInDegree! - section.trajectoryPoint1.angleInfo?.angleInDegree!;
        // hitPointRotate = hitPointRotate * 2;

        let vectorTrajPerpendicular = MathUtils.vectorPerpendicular(vectorTraj, true);
        vectorTrajPerpendicular = MathUtils.scale2(
          vectorTrajPerpendicular,
          1 / MathUtils.length(vectorTrajPerpendicular)
        );
        return { hitPoint, hitPointRotate, vectorTrajPerpendicular };
      } else {
        throw 'can find trajectory section on MD: ' + md;
      }
    }

    GetTrajectorySection3(p: Point) {
      let hitPoint = undefined;
      for (let i = 0; i < this.dataPointsArray.length; i++) {
        const trajPoint = this.dataPointsArray[i];
        hitPoint = this.IsPointInSection(p, trajPoint);
        if (hitPoint !== undefined) break;
      }
      return hitPoint;
    }
    IsPointInSection(p: Point, trajPoint: TrajectoryStationDataPoint) {
      if (trajPoint.next === undefined) {
        return undefined;
      }
      let p1 = trajPoint.GetPoint();
      let p2 = trajPoint.next!.GetPoint();
      let fTraj = MathUtils.LinearFunction.GetLinearFuncByLine({ point1: p1, point2: p2 });

      let line1 = new MathUtils.LinearFunction(p1, trajPoint.angleInfo?.angleInDegree! + 90);
      let line2 = new MathUtils.LinearFunction(p2, trajPoint.next?.angleInfo?.angleInDegree! + 90);
      let intersect = line1.intersect(line2);
      //TODO: parallel?
      if (intersect !== undefined) {
        let fLineToHitPoint = MathUtils.LinearFunction.GetLinearFuncByLine({ point1: intersect, point2: p });
        let hitPoint = fLineToHitPoint.intersect(fTraj);
        // if (hitPoint === undefined) {
        //   return hitPoint;
        // }
        if (
          hitPoint !== undefined &&
          MathUtils.inRange(p1.X, p2.X, hitPoint.X) &&
          MathUtils.inRange(p1.Y, p2.Y, hitPoint.Y)
        ) {
          let md = 0;
          let p1p2Length = MathUtils.length(MathUtils.vector(p1, p2));
          let p1HitPointLength = MathUtils.length(MathUtils.vector(p1, hitPoint));
          let mdDiff = trajPoint.next!.md - trajPoint.md;
          let rationToPoint1 = p1HitPointLength / p1p2Length;
          md = rationToPoint1 * mdDiff + trajPoint.md;

          if (!Number.isNaN(hitPoint.X)) return { hitPoint, intersect, fLineToHitPoint, md, rationToPoint1, trajPoint };
        }
      } else {
        //intersect is undefined, the two lines are in parallel.
      }

      return undefined;
    }

    // FollowTrajectory(
    //   // trajectData: GeosteeringData.TrajectoryDataPointDictionary,
    //   tvd: number,
    //   md: number,
    //   val: number,
    //   field: Field,
    //   // vMin = 0,
    //   // vMax = 50,
    //   trackNumber = 0,
    //   trackInfo = GeosteeringUtility.TRACK_INFO
    // ) {
    //   let xMin = trackInfo.Offset * (trackNumber + 1);
    //   let xMax = xMin + trackInfo.TrackWidth;
    //   let vMin = field.config.min ?? 0;
    //   let vMax = field.config.max ?? 100;
    //   let perc = (val - vMin) / (vMax - vMin);
    //   let vTransformed = xMin + perc * (xMax - xMin);

    //   let { trajectoryPoint1, trajectoryPoint2 } = this.GetTrajectorySection(md);
    //   let startedAdjustedPoint = TrajectoryDataPointDictionary.GetStartAdjustedPoint(
    //     trajectoryPoint1,
    //     trajectoryPoint2,
    //     trackInfo
    //   );

    //   return vTransformed;
    // }

    //#region Static functions

    static GetLengthOfPoints(point1: Point, point2: Point): number {
      return Math.sqrt(Math.pow(point2.X - point1.X, 2) + Math.pow(point2.Y - point1.Y, 2));
    }

    static GetAngleInfo(relativePoint1: TrajectoryStationDataPoint, relativePoint2: TrajectoryStationDataPoint) {
      let relativePoint1Point: Point = { X: relativePoint1.vertSect, Y: relativePoint1.tvd };
      let relativePoint2Point: Point = { X: relativePoint2.vertSect, Y: relativePoint2.tvd };

      let yDiff = relativePoint2Point.Y - relativePoint1Point.Y;
      let xDiff = relativePoint2Point.X - relativePoint1Point.X;

      let tanTheta = yDiff / xDiff;

      let radian = Number.isFinite(tanTheta) ? Math.atan(tanTheta) : TrajectoryCalculationUtility.Radian90Degree;

      // Solution to miss display
      if ((xDiff < 0 && yDiff > 0) || (xDiff > 0 && yDiff < 0)) {
        radian = Math.PI + radian;
      }

      let angleInfo = new AngleInfo();
      angleInfo.angleInDegree = Number.isNaN(radian) ? 0 : TrajectoryCalculationUtility.ConvertRadianToDegree(radian);
      angleInfo.sinTheta = Math.sin(radian);
      angleInfo.cosTheta = Math.cos(radian);
      return angleInfo;
    }

    static CalculateAngleInfo(dataPoint: TrajectoryStationDataPoint) {
      let relativePoint0: TrajectoryStationDataPoint | undefined = undefined;
      let relativePoint1: TrajectoryStationDataPoint | undefined = undefined;
      let relativePoint2: TrajectoryStationDataPoint | undefined = undefined;

      if (dataPoint === undefined) {
        return;
      }

      if (dataPoint.next !== undefined) {
        if (dataPoint.prev !== undefined) {
          relativePoint0 = dataPoint.prev;
        }

        relativePoint1 = dataPoint;
        relativePoint2 = dataPoint.next;
      } else if (dataPoint.prev !== undefined) {
        relativePoint1 = dataPoint.prev;
        relativePoint2 = dataPoint;
      }

      if (relativePoint1 === undefined || relativePoint2 === undefined) {
        return;
      }

      let relativePoint0Point: Point = { X: 0, Y: 0 };
      if (relativePoint0 !== undefined) {
        relativePoint0Point = relativePoint0.GetPoint();
      }

      let relativePoint1Point = relativePoint1.GetPoint();
      let relativePoint2Point = relativePoint2.GetPoint();

      let yDiff = 0;
      let yDiff2 = 0;
      let xDiff = 0;
      let xDiff2 = 0;

      if (relativePoint0Point.X !== 0 && relativePoint0Point.Y !== 0) {
        let point01Length = TrajectoryDataPointDictionary.GetLengthOfPoints(relativePoint0Point, relativePoint1Point);
        let point12Length = TrajectoryDataPointDictionary.GetLengthOfPoints(relativePoint1Point, relativePoint2Point);

        if (point01Length > point12Length) {
          let y01 = relativePoint1Point.Y - relativePoint0Point.Y;
          let x01 = relativePoint1Point.X - relativePoint0Point.X;
          let factor = (point01Length - point12Length) / point01Length;
          let newY0 = relativePoint0Point.Y + factor * y01;
          let newX0 = relativePoint0Point.X + factor * x01;
          let newPoint0: Point = { X: newX0, Y: newY0 };

          yDiff = relativePoint2Point.Y !== newPoint0.Y ? relativePoint2Point.Y - newPoint0.Y : y01;
          xDiff = relativePoint2Point.X !== newPoint0.X ? relativePoint2Point.X - newPoint0.X : x01;
        } else {
          let y12 = relativePoint2Point.Y - relativePoint1Point.Y;
          let x12 = relativePoint2Point.X - relativePoint1Point.X;
          let factor = (point12Length - point01Length) / point12Length;
          let newY2 = relativePoint2Point.Y - factor * y12;
          let newX2 = relativePoint2Point.X - factor * x12;
          let newPoint2: Point = { X: newX2, Y: newY2 };

          yDiff = newPoint2.Y !== relativePoint0Point.Y ? newPoint2.Y - relativePoint0Point.Y : y12;
          xDiff = newPoint2.X !== relativePoint0Point.X ? newPoint2.X - relativePoint0Point.X : x12;
        }
      } else {
        yDiff = relativePoint2Point.Y - relativePoint1Point.Y;
        xDiff = relativePoint2Point.X - relativePoint1Point.X;
      }

      yDiff2 = relativePoint2Point.Y - relativePoint1Point.Y;
      xDiff2 = relativePoint2Point.X - relativePoint1Point.X;

      // calculate adjusted angle point info
      let tanTheta = yDiff / xDiff;

      let radian = Number.isFinite(tanTheta) ? Math.atan(tanTheta) : TrajectoryCalculationUtility.Radian90Degree;

      // Solution to miss display
      if (xDiff < 0) {
        radian = Math.PI + radian;
      }

      dataPoint.angleInfo = new AngleInfo();
      dataPoint.angleInfo.angleInDegree = Number.isNaN(radian)
        ? 0
        : TrajectoryCalculationUtility.ConvertRadianToDegree(radian);
      dataPoint.angleInfo.sinTheta = Math.sin(radian);
      dataPoint.angleInfo.cosTheta = Math.cos(radian);

      // calculate perpendicular angle point info
      tanTheta = yDiff2 / xDiff2;
      if (!Number.isFinite(tanTheta)) {
        radian = TrajectoryCalculationUtility.Radian90Degree;
      } else if (Number.isNaN(tanTheta)) {
        radian = 0.0;
      } else {
        radian = Math.atan(tanTheta);
      }

      // Solution to miss display
      if (xDiff2 < 0) {
        radian = Math.PI + radian;
      }

      dataPoint.perpendicularAngleInfo = new AngleInfo();

      dataPoint.perpendicularAngleInfo.angleInDegree = Number.isNaN(radian)
        ? 0
        : TrajectoryCalculationUtility.ConvertRadianToDegree(radian);

      dataPoint.perpendicularAngleInfo.sinTheta = Math.sin(radian);
      dataPoint.perpendicularAngleInfo.cosTheta = Math.cos(radian);
    }

    protected static GetStartAdjustedPoint(
      relativePoint1: TrajectoryStationDataPoint,
      relativePoint2: TrajectoryStationDataPoint,
      trackInfo = TrajectoryCalculationUtility.TRACK_INFO
    ): TrajectoryStationDataPoint | undefined {
      if (relativePoint1 === undefined || relativePoint2 === undefined || trackInfo.TrackWidth === 0) {
        return;
      }

      // let relativePoint1Point = relativePoint1.GetPoint(GeosteeringTemplate, IsVerticalSectionAsIndex);
      // let relativePoint2Point = relativePoint2.GetPoint(GeosteeringTemplate, IsVerticalSectionAsIndex);
      let relativePoint1Point: Point = relativePoint1.GetPoint();
      let relativePoint2Point: Point = relativePoint1.GetPoint();

      let angleInfo1 = relativePoint1.angleInfo!;
      let angleInfo2 = relativePoint2.angleInfo!;

      // Angle of impact area
      let angleBetaInDegree = 90 - Math.abs(angleInfo1.angleInDegree) - Math.abs(angleInfo2.angleInDegree);
      let cosBeta = Math.cos(TrajectoryCalculationUtility.ConvertDegreeToRadian(angleBetaInDegree));
      let diagonalLength = trackInfo.Offset + trackInfo.TrackWidth;
      let trajectoryLenghtOfImpact = cosBeta * diagonalLength;

      let trajectoryLength = TrajectoryDataPointDictionary.GetLengthOfPoints(relativePoint1Point, relativePoint2Point);

      if (trajectoryLength > trajectoryLenghtOfImpact) {
        let md =
          relativePoint2.md - (trajectoryLenghtOfImpact / trajectoryLength) * (relativePoint2.md - relativePoint1.md);
        return TrajectoryDataPointDictionary.GetRelativeTrajectoryPosition(relativePoint1, relativePoint2, md);
      }

      return relativePoint1;
    }

    protected static GetRelativeTrajectoryPosition(
      relativePoint1: TrajectoryStationDataPoint,
      relativePoint2: TrajectoryStationDataPoint,
      md: number
    ): TrajectoryStationDataPoint {
      if (relativePoint1 === relativePoint2) {
        return relativePoint1;
      }

      let relativePoint1Point = relativePoint1.GetPoint();
      let relativePoint2Point = relativePoint2.GetPoint();

      //if (double.IsNaN(relativePoint1.AngleInfo.AngleInDegree))
      //{
      //    relativePoint1.AngleInfo = GetAngleInfo(relativePoint1, relativePoint2, trajectoryModel);
      //}
      //AngleInfo angleInfo = relativePoint1.AngleInfo;// GetAngleInfo(relativePoint1, relativePoint2, trajectoryModel);

      let percentageOfPositionInTrajectory = (md - relativePoint1.md) / (relativePoint2.md - relativePoint1.md);
      let exactRelativeTrajectoryPointIndex =
        percentageOfPositionInTrajectory * (relativePoint2.md - relativePoint1.md) + relativePoint1.md;
      let exactRelativeTrajectoryPointXPosition =
        percentageOfPositionInTrajectory * (relativePoint2Point.X - relativePoint1Point.X) + relativePoint1Point.X;
      let exactRelativeTrajectoryPointYPosition =
        percentageOfPositionInTrajectory * (relativePoint2Point.Y - relativePoint1Point.Y) + relativePoint1Point.Y;
      let exactRelatvieTrajectoryPointDispNs =
        percentageOfPositionInTrajectory * (relativePoint2.dispNs - relativePoint1.dispNs) + relativePoint1.dispNs;
      let exactRelatvieTrajectoryPointDispEw =
        percentageOfPositionInTrajectory * (relativePoint2.dispEw - relativePoint1.dispEw) + relativePoint1.dispEw;

      // if(Number.isNaN(relativePoint1.perpendicularAngleInfo!.angleInDegree))
      // {

      //     CalculateAngleInfo(relativePoint1);
      // }

      let relative = new TrajectoryStationDataPoint(exactRelativeTrajectoryPointIndex);

      relative.md = exactRelativeTrajectoryPointIndex;
      relative.vertSect = exactRelativeTrajectoryPointXPosition;
      relative.dispEw = exactRelatvieTrajectoryPointDispEw;
      relative.dispNs = exactRelatvieTrajectoryPointDispNs;
      relative.angleInfo = relativePoint1.perpendicularAngleInfo;
      relative.tvd = exactRelativeTrajectoryPointYPosition;

      // if (IsTvdssMode)
      // {
      //     relative.Tvd = GetTvdFromY(exactRelativeTrajectoryPointYPosition);
      //     relative.RecalculateTvdss(GeosteeringTemplate.ElevationForTVDSS);
      // }
      // else
      // {
      //     relative.Tvd = exactRelativeTrajectoryPointYPosition;
      //     relative.RecalculateTvdss(GeosteeringTemplate.ElevationForTVDSS);
      // }

      return relative;
    }

    static rowToDataPoint(row: any): TrajectoryStationDataPoint {
      let p: TrajectoryStationDataPoint = new TrajectoryStationDataPoint(row['md']);
      Object.assign(p, row);
      return p;
      //   return row as TrajectoryStationDataPoint;
    }

    static GetRotatedPosition(
      pointToBeRotated: Point,
      centerPoint: Point,
      angleInfo: AngleInfo,
      curvePosition: CurvePositions
    ): Point {
      if (angleInfo == null) {
        return pointToBeRotated;
      }

      let cosTheta = curvePosition == CurvePositions.Outer ? angleInfo.cosTheta : -angleInfo.cosTheta;
      let sinTheta = curvePosition == CurvePositions.Outer ? angleInfo.sinTheta : -angleInfo.sinTheta;

      let xPointPositionAfterRotation =
        cosTheta * (pointToBeRotated.X - centerPoint.X) -
        sinTheta * (pointToBeRotated.Y - centerPoint.Y) +
        centerPoint.X;
      let yPointPositionAfterRotation =
        sinTheta * (pointToBeRotated.X - centerPoint.X) +
        cosTheta * (pointToBeRotated.Y - centerPoint.Y) +
        centerPoint.Y;

      // return _geosteeringTemplate.UseFixScale ? new Point(xPointPositionAfterRotation, yPointPositionAfterRotation).ScreenToData(Transform) :
      //     new Point(xPointPositionAfterRotation, yPointPositionAfterRotation);
      return { X: xPointPositionAfterRotation, Y: yPointPositionAfterRotation };
    }

    //   static GetPositionInSpaceWithAdjustment(
    //     relativePoint1: TrajectoryStationDataPoint,
    //     relativePoint2: TrajectoryStationDataPoint,
    //     trackInfo: GeosteeringUtility.TrackInfo,
    //     curveDataPoint: Point,
    //     curvePosition = CurvePositions.Outer,
    //     isFollowTrajectoryPath = true
    //   ): { exactRelativeTrajectoryPoint: TrajectoryStationDataPoint; position: Point } {
    //     let relativePoint1Point = relativePoint1.GetPoint();
    //     let relativePoint2Point = relativePoint2.GetPoint();

    //     let pointYPositionInTrack = 0;

    //     //trackInfo.FixedOffset = false;
    // //     if (!trackInfo.FixedOffset)
    // //     pointYPositionInTrack = offset + trackInfo.CalculateValuePositionInTrack(curveDataPoint.DataAsDouble);
    // // else
    // //     pointYPositionInTrack = offset + ConvertLenghtInHeightInData(trackInfo.CalculateValuePositionInTrack(curveDataPoint.DataAsDouble));

    // // pointYPositionInTrack = offset + trackInfo.CalculateValuePositionInTrack(curveDataPoint.DataAsDouble);

    //   }

    //#endregion
  }
}
/* eslint-enable */
